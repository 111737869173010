<template>
    <div
        class="ui-slider" tabindex="0" ref="slider"
        v-bind:class="{ min: value === 0, max: value === 100 }"
        @mousedown.prevent="sliderClick" @keydown.left.prevent="decrement" @keydown.right.prevent="increment"
    >
        <div class="ui-slider-containment" ref="container"></div>

        <div class="ui-slider-wrapper">
            <div class="ui-slider-track-container">
                <div class="ui-slider-track"></div>
                <div class="ui-slider-track-fill" v-bind:style="{ width: value + '%'}"></div>
            </div>

            <div class="ui-slider-thumb-container" ref="thumb">
                <div class="ui-slider-focus-ring"></div>
                <div class="ui-slider-thumb"></div>
            </div>
        </div>
    </div>
</template>
<style src="./slider.scss" lang="scss"></style>
<script src="./vdlSlider.ts" lang="ts"></script>
