import { App } from 'vue';
import { VueMaterial } from '@Voice123/v1_material/core/index';
import { THEMES, THEME } from './constants';

export function install(app:App) {

  const material = new VueMaterial();
  app.use(material);
  material.registerTheme(THEMES);
  material.setDefaultTheme(THEME);
}