<template>
  <vdl-basic-voice-actor-card
    v-if="providerInfo.id"
    :key="providerInfo.id" :provider="providerInfo"
    :extendedCard="_extended"
    :lastActiveText="_lastActiveText"
    :blured="_blured"
    :keyword="_keyword"
    :force-link-default="_forceLinkDefault"
    @voiceover:book="onBook"
    @voiceover:contact="onContact"
    ></vdl-basic-voice-actor-card>
</template>

<script lang="ts">
import { Options, Prop, Vue } from 'vue-property-decorator';
import { formatToRelativeTime } from '../utils';
import api from '../api';
@Options({})
export default class VoiceActorCard extends Vue {
  providerInfo: any = {};
  @Prop()
  username!: string;
  @Prop()
  provider!: any;
  @Prop()
  keyword = 'asd';
  get _keyword() {
    return this.keyword;
  }
  @Prop()
  blured!: string;
  get _blured() {
    return this.blured === 'true';
  }
  @Prop()
  extended!: string;
  get _extended() {
    return this.extended === 'true';
  }
  @Prop()
  forceLinkDefault!: string;
  get _forceLinkDefault() {
    return this.forceLinkDefault === 'true';
  }
  get _lastActiveText() {
    return formatToRelativeTime(new Date(this.providerInfo.user.last_interaction));
  }
  onBook() {
    window.location.href = `https://voice123.com/book/${this.provider.user.username}`;
  }
  onContact() {
    window.location.href = `https://voice123.com/${this.provider.user.username}?dialog=contact`;
  }
  getProvider() {
    const that = this;
    api.get(`providers/search/${this.username}?service=voice_over`)
      .then(({ data: results }) => {
        if (results && results.length) {
          const provider = results[0];
          if (!provider.relevant_sample.file) {
            delete provider.relevant_sample;
          }
        
          that.providerInfo = provider;
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  mounted() {
    if(this.provider) {
      this.providerInfo = this.provider;
    } else if (this.username) {
      this.getProvider();
    } else {
      throw new Error('The username or the provider property is required to render the VoiceActorCard component');
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "../../node_modules/@Voice123/v1_material/core/stylesheets/core.scss";
  @import"../../sass/v123.scss";

  .vdl-icon-badge:before {
    content: "\e903";
  }
</style>
