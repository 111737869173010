const extensions = {
    recording_capabilities: {
      1003: {
        targetAttribute: 'locations',
        nested: [{
          attribute: 'locations',
          name: 'Location',
          type: 'location',
          view: 'read_only',
          validations: [
            { rule: 'required' },
            { rule: 'location_input' },
            { rule: 'array_length'}
          ]
        }]
      }
    }
  };
  
  
  const extendAttributes = (attribute) => {
    return (value) => {
      if (extensions[attribute] && extensions[attribute][value.id]) {
        return {...value,
          ...extensions[attribute][value.id]
        };
      }
      return value;
    };
  };
  
  const areEnable = (item) => {
    return item.filter((item) => item.enabled);
  };
  
  const getMatchingAttr = (attributes, attributeName) => {
    return attributes.filter((attribute) => attribute.name === attributeName);
  };
  
  const updateAttrCurrenciesValues = (attributes) => {
    let currenciesObject = getMatchingAttr(attributes, 'currencies')[0];
  
    return {
      ...currenciesObject,
      values: getMatchingAttr(attributes, 'currencies').reduce((prev, curV) => {
        return areEnable(curV.values);
      }, [])
    };
  };
  
  const updateCurrencies = (attributes, field) => {
  
    const tripleCheck = (target) => {
      if (target.values) {
        target.values = target.values.length > 0 && target.values.map((x) => tripleCheck(x)) || target.values;
      }
      if (target.nested) {
        target.nested = target.nested.length > 0 && target.nested.map((x) => tripleCheck(x)) || target.nested;
      }
      if (Object.keys(target).indexOf('currencies') > -1 ||  Array.isArray(target) && target[0].currencies) {
        target.currencies = updateAttrCurrenciesValues(attributes);
      }
      if (target.type === 'selector' && target.currencies) {
        target.values = getMatchingAttr(attributes, 'currencies').reduce((prev, curV) => {
          return areEnable(curV.values);
        }, []);
      }
      return target;
    };
  
    return tripleCheck(field);
  };
  
  const updateAttrValuesToSchema = (attributes, field, fieldName = 'values') => {
    const defaultValue = field[fieldName] && field[fieldName].length ? field[fieldName] : [];
    return getMatchingAttr(attributes, field.attribute_alias || field.attribute).reduce((preV, curV) => {
      return areEnable(curV[fieldName] || []).map(extendAttributes(curV.name));
    }, defaultValue);
  };
  
  export default (attributes, schema) => {
    return schema.map((field) => {
      updateCurrencies(attributes, field);
      const nestedElements = ['nested', 'conditionalNested'].filter(el => field[el]?.length);
      if (nestedElements.length) {
        nestedElements.forEach((nestedElement) => {
          field[nestedElement] = field[nestedElement].map((nestedField) => {
            updateCurrencies(attributes, nestedField);
            return {
              ...nestedField,
              values: updateAttrValuesToSchema(attributes, nestedField),
              excludedValues: updateAttrValuesToSchema(attributes, nestedField, 'excluded_values')
            };
          });
        });
      }
      return {
        ...field,
        values: updateAttrValuesToSchema(attributes, field),
        excludedValues: updateAttrValuesToSchema(attributes, field, 'excluded_values')
      };
    });
  };
  