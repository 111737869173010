<template>
    <div className="pro-card">
            <div className="heading">
                <div className="va-info">
                    <img
                        :src="image"
                        :alt="`${name} image`"
                    />
                    <div className='va-details'>
                        <span>{{name}}</span>
                    </div>
                </div>
                <div className='buttons'>
                    <div class="vdl-only-mobile">
                        <tdl-audio-player :url="sampleUrl" :simple="true" :background-theme="false"></tdl-audio-player>
                    </div>
                </div>
            </div>
            <div className="pro-content">
                <div className='pro-attributes'>
                    <div>
                        <md-icon :mdSvg="mdiTranslate" />
                        <span>Language</span>
                        <span className='attribute'>{{language}}</span>
                    </div>
                    <div>
                        <md-icon :mdSvg="mdiHumanMaleFemale" />
                        <span>Gender & age</span>
                        <span className='attribute'>{{gender}}</span>
                    </div>
                    <div>
                        <md-icon :mdSvg="mdiTimerOutline" />
                        <span>Turnaround time</span>
                        <span className='attribute'>{{turnaround}} day</span>
                    </div>
                </div>
                <div class="vdl-no-mobile">
                    <tdl-audio-player :url="sampleUrl" :simple="true" :background-theme="false"></tdl-audio-player>
                </div>
            </div>
        </div>
</template>

<script lang="ts">
import { Vue, Prop, Inject } from 'vue-property-decorator';
import { mdiTranslate, mdiHumanMaleFemale, mdiCash, mdiHeartOutline, mdiTimerOutline, mdiPlayCircleOutline,  } from '@mdi/js';

export default class ProCard extends Vue {
    mdiTranslate = mdiTranslate;
    mdiHumanMaleFemale = mdiHumanMaleFemale;
    mdiCash = mdiCash;
    mdiHeartOutline = mdiHeartOutline;
    mdiTimerOutline = mdiTimerOutline;
    mdiPlayCircleOutline = mdiPlayCircleOutline;

    @Prop({default: ''})
    name: string

    @Prop({default: ''})
    image: string
    
    @Prop({default: ''})
    language: string

    @Prop({default: ''})
    gender: string

    @Prop({default: 1})
    turnaround: number

    @Prop({default: ''})
    profileUrl: string

    @Prop({default: ''})
    sampleUrl: string

}
</script>

<style lang="scss" scoped>
@import '../../../sass/vars.scss';
.cards-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

    .pro-card {
        box-shadow: 82px 181px 56px 0px rgba(218, 210, 210, 0.00), 52px 116px 51px 0px rgba(218, 210, 210, 0.01), 29px 65px 43px 0px rgba(218, 210, 210, 0.05), 13px 29px 32px 0px rgba(218, 210, 210, 0.09), 3px 7px 17px 0px rgba(218, 210, 210, 0.10);
        background-color: #fff;
        max-width: 500px;
        margin: 30px auto 0;
        border-radius: 10px;
        
        .heading {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 10px 1rem;
            border-bottom: 1px solid $brand-light;

            .va-info {
                display: flex;
                align-items: center;
                border: none;
                img {
                    width: 40px;
                    height: 40px;
                    flex-shrink: 0;
                    border-radius: 40px;
                }

                .va-details {
                    margin-left: 15px;
                    font-weight: 600;
                    >span {
                        color: $text-contrast-87;
                        font-size: 14px;
                    }

                    div {
                        text-align: left;
                        font-size: 12px;
                        color: #757575;

                        .material-icons {
                            margin-bottom: 3px;
                        }
                    }
                }
            }

            .buttons {
                display: inline-flex;
                align-items: center;
                .btn {
                    height: 36px;
                    color: #00ACC1;
                    background-color: unset;
                    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.24);
                }

                .md-button {
                    color: #757575;
                    min-width: 30px;
                    padding: 0;
                    margin-right: 0;
                }

                div .tdl-audio-player {
                    background-color: unset;

                    .player-content {
                        height: unset;

                        .md-button.md-icon-button {
                            color: #00ACC1;
                        }
                    }
                }
            }
        }

        .pro-content {
            display: flex;
            justify-content: space-between;
            margin-top: 30px;
            padding: 0 1rem 18px;
            align-items: center;

            .pro-attributes {
                text-align: left;
                font-size: 11px;

                div {
                    display: flex;
                    align-items: center;
                    margin-bottom: 12px;

                    .md-icon {
                        margin: unset;
                        color: $text-contrast-54;
                    }

                    span:not(.attribute) {
                        width: 100px;
                        color: $text-contrast-87;
                        margin-left: 13px
                    }

                    .attribute {
                        display: inline-block;
                        height: auto;
                        margin-right: 5px;
                        padding: 0 10px;
                        color: $text-contrast-54;
                        border-radius: 10px;
                        background-color: #F2F2F2;
                        text-overflow: ellipsis;
                        overflow: hidden;

                        @media screen and (max-width: 420px) {
                            max-width: 40%;
                            white-space: nowrap;
                        }
                    }
                }
            }

            div .tdl-audio-player {
                background-color: unset;
                margin-right: 1rem;
                margin-bottom: 20px;

                .player-content {
                    height: unset;

                    .md-button.md-icon-button {
                        color: #00ACC1;
                    }
                }
            }

            > .material-icons {
                margin-right: 2rem;
                margin-bottom: 20px;
            }
        }
        @media screen and (max-width: 960px) {
            width: 100%;
        }

        @media screen and (max-width: 440px) {
            max-width: 300px;
        }
    }
}
</style>