<template>
    <div class="steps-container">
        <div class="step-one">
            <div class="instructions-container">
              <h3>1. Insert your recording length</h3>
              <div class="radio-wrapper">
                <div>
                <md-radio md-value="1" v-model="timeCounterSelected" class="md-primary">Length</md-radio>
                <md-layout md-gutter md-row>
                  <md-input-container  class="word-container">
                    <label for="search-keyword" class="placeholder-label"></label>
                    <md-input  min="0" required  type="number"
                    v-model="seconds" ref="searchInput" placeholder="" data-hj-allow class="browser-default"></md-input>
                  </md-input-container>
                  <md-input-container class="time-unit-container">
                    <label for="time-unit-select" class="placeholder-label"></label>
                    <md-select id="time-unit-select"  v-model="timeUnit" placeholder="seconds" >
                      <md-option value="seconds">seconds</md-option>
                      <md-option value="minutes">minutes</md-option>
                      <md-option value="hours">hours</md-option>
                    </md-select>
                  </md-input-container>
                </md-layout>
              </div>
              </div>
            </div>
          </div>
        <div class="step-two">
            <div class="instructions-container">
                <h3>2. Get your words-to-time estimation</h3>
                <div class="radio-wrapper">
                    <div>
                        <h3 class="reading-time">{{ readingTime }}</h3>
                        <h4 class="reading-speed">Voice actor's reading speed: {{ wordsPerMinute }} words per minute</h4>
                        <h4 class="recommendation-text">Recommended for: {{ recommendation }}</h4>
                    </div>
                    <div>
                        <md-layout md-gutter md-row>
                            <p class="speed-text">Adjust the voice over speed</p>
                            <div class="slider-container">
                                <vdl-slider class="slider" :value="initialSliderValue" keyboardStep="15"
                                    @valueChange="calculateWpm"></vdl-slider>
                                <div class="mobile-slider-text">
                                    <h5>Slower</h5>
                                    <h5 class="left-text">Faster</h5>
                                </div>
                            </div>
                        </md-layout>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { Vue, Watch } from 'vue-property-decorator';
import VdlSlider from './vdlSlider/vdlSlider.vue';

export default class LengthCalculator extends Vue {
    timeCounterSelected: any = 1;
    seconds: number = 0;
    readingTime: any = '0 seconds';
    wordsPerMinute: number = 140;
    timeUnit: string = 'seconds';

    recommendations = {
      120: 'Slow Audiobook Narrration',
      130: 'Audiobook Narration',
      140: 'Conversational Script',
      150: 'Explainer Script',
      160: 'Fast Explainer Script',
      180: 'Commercial Script',
      190: 'Fast Commercial Script',
    };


    @Watch('seconds')
    onSecondsChanged() {
      this.readingTime = this.getReadingTime();
    }

    @Watch('wordsPerMinute')
    onWpmChanged() {
      this.readingTime = this.getReadingTime();
    }

    @Watch('timeUnit')
    onUnitChanged() {
      this.readingTime = this.getReadingTime();
    }

    calculateWpm(sliderValue) {
      const maxWpm = 190;
      const minWpm = 120;
      this.wordsPerMinute = minWpm +  Math.round(sliderValue * (maxWpm - minWpm) / 100);
    }

    getReadingTime() {
      const complement = 'words';
      let words;
        switch (this.timeUnit) {
          case 'seconds':
            words = (this.seconds ? Math.round(this.seconds * this.wordsPerMinute / 60 ) : 0);
            return `${words} ${ words === 1 ? 'word' : complement}`;
          case 'minutes':
            words = (this.seconds ? Math.round(this.seconds * this.wordsPerMinute ) : 0);
            return `${words} ${ words === 1 ? 'word' : complement}`;
          case 'hours':
            words = (this.seconds ? Math.round(this.seconds * this.wordsPerMinute * 60) : 0);
            return `${words} ${ words === 1 ? 'word' : complement}`;
        }
    }

    get initialSliderValue() {
      const maxWpm = 190;
      const minWpm = 120;
      return Math.round( Math.abs(this.wordsPerMinute - minWpm) * 100 / Math.abs(maxWpm - minWpm));
    }

    get recommendation() {
      const temp = Object.keys(this.recommendations).reduce((acc, current: any) => {
        const difference = Math.abs(this.wordsPerMinute - current);
        acc[difference] = current;
        return acc;
      }, {});
      const differencesArr = [...Object.keys(temp)].sort((a: any, b: any) => (a - b));
      return this.recommendations[temp[differencesArr[0]]];
    }
}
</script>

<style lang="scss" scoped>
@import "../../node_modules/@Voice123/v1_material/core/stylesheets/core.scss";
@import"../../sass/v123.scss";

.steps-container {
    display: grid;
    grid-template-columns: 49% 49%;
    align-items: center;
    justify-content: space-between;
    position: relative;
    margin: 50px auto 0;

    @media screen and (min-width: 721px) {
        min-height: 336px;
    }



    @media screen and (max-width: 720px) {
        grid-template-columns: 100%;
        padding: unset;
    }

    .step-one,
    .step-two {
        border: 1px solid $brand-blue;
        border-radius: 10px;
        display: flex;
        align-items: center;
        height: 100%;
        padding: 2rem;
    }

    .step-one {
        grid-column: 1;
        height: 100%;
    }

    .step-two {
        grid-column: 2;

        @media screen and (max-width: 720px) {
            grid-column: 1;
            margin-top: 60px;
        }

        .reading-time {
            margin-top: 30px;
            font-size: 20px;
            font-weight: 600;
            line-height: 28px;
        }

        .reading-speed {
            margin-bottom: 0;
            margin-top: 30px;
            font-weight: 400;
            font-size: 16px;
            color: black;
            line-height: 20px;
        }

        .recommendation-text {
            margin-top: 0;
            font-weight: 400;
            font-size: 16px;
            color: black;
            line-height: 20px;
            margin-bottom: 20px;
        }
    }

    .instructions-container {
        width: 100%;
        text-align: left;
        height: 100%;

        @media screen and (min-width: 721px) and (max-width: 1120px) {
            width: 90%;
        }

        @media screen and (min-width: 1121px) and (max-width: 1380px) {
            width: 75%;
        }

        @media screen and (max-width: 720px) {
            width: 90%;
        }

        .word-container {
            width: 30%;
            margin-right: 20px;

            @media screen and (min-width: 721px) {
                max-width: 50px;
            }
        }

        .time-unit-container {
            width: 60%;

            @media screen and (min-width: 721px) {
                max-width: 100px;
            }
        }

    }

    .radio-wrapper {
        padding: 0 10%;
        margin-top: 10px;
    }

    .slider-container {
        display: block;
        width: 100%;

        @media screen and (max-width: 720px) {
            display: block;
        }

        .mobile-slider-text {
            display: inline-flex;
            width: 70%;

            @media screen and (max-width: 720px) {
                width: 100%
            }

            .left-text {
                text-align: right;
            }

            h5 {
                width: 50%;
                margin: 0;
                font-size: 14px;
                color: rgba(0, 0, 0, 0.38);
            }
        }
    }

    .speed-text {
        margin-bottom: -3px;
        font-size: 12px;
        color: rgba(0, 0, 0, 0.38);
    }

    .slider {
        width: 70%;
        position: relative;
        top: 8px;
        margin: 0;

        @media screen and (max-width: 720px) {
            width: 100%
        }
    }
}
</style>