<template>
  <div class="post_project">
    <div class="post_project-form hide-on-med-and-down">
      <form
        style=""
        class="formkit-form"
        id="postFilterModel"
        name="postFilterModel"
      >
        <div class="form-content">
          <md-list class="fields md-double-line">
            <li class="field-item autocomplete">
              <ul class="unestiled-list">
                <div id="post-languages">
                  <div class="field-item-list">
                    <vdl-autocomplete-form-field
                      :field="filtersSchema[0]"
                      v-model="languagesModel"
                      :svgIcon="mdiTranslate"
                      :name="filtersSchema[0].name"
                      :caption="['Search language']"
                      :maxItems="1"
                    >
                    </vdl-autocomplete-form-field>
                  </div>
                </div>
              </ul>
            </li>
            <li class="field-item autocomplete">
              <ul class="unestiled-list">
                <div id="genders">
                  <div class="field-item-list">
                    <vdl-autocomplete-form-field
                      :field="filtersSchema[1]"
                      v-model="genderModel"
                      :svgIcon="mdiHumanMaleFemale"
                      :name="filtersSchema[1].name"
                      :caption="['Add gender']"
                      :maxItems="1"
                    >
                    </vdl-autocomplete-form-field>
                  </div>
                </div>
              </ul>
            </li>
            <a
              class="md-button md-accent"
              :href="`/app/projects/post?languages=${extractValues(
                languagesModel
              )}&gender=${extractValues(genderModel)}`"
              target="_blank"
              >Post</a
            >
          </md-list>
        </div>
      </form>
    </div>
    <a
      class="md-button btn md-accent hide-on-large-only"
      href="/app/projects/post?step=1"
    >
      GET STARTED
</a>
  </div>
</template>

<script lang="ts">
import { Vue } from "vue-property-decorator";
import api from "../api";
import {
  mdiTranslate,
  mdiHumanMaleFemale,
  mdiInformationOutline,
  mdiCash,
} from "@mdi/js";
import mapAttributesToSchema from "../helpers";

export default class PostProject extends Vue {
  mdiInformationOutline = mdiInformationOutline;
  mdiCash = mdiCash;
  mdiTranslate = mdiTranslate;
  mdiHumanMaleFemale = mdiHumanMaleFemale;
  attributes: Array<any> = [];
  languagesModel: any = [];
  genderModel: any = [];
  filtersSchema = [
    {
      order: 1,
      attribute: "languages",
      svgIcon: mdiTranslate,
      name: "Language",
      operators: true,
      type: "autocomplete",
      view: "chips",
      threshold: -1,
      noInsetNested: true,
      values: [],
    },
    {
      order: 2,
      attribute: "genders",
      id: "genders",
      svgIcon: mdiHumanMaleFemale,
      name: "Gender",
      operators: true,
      type: "autocomplete",
      values: [],
    },
  ];

  async mapSchemas() {
    this.filtersSchema = mapAttributesToSchema(
      this.attributes,
      this.filtersSchema
    );
  }

  async mounted() {
    const headers = {
      "studio-service": true,
    };
    const { data } = await api.get("attributes/", {
      headers,
    });
    this.attributes = data;
    this.mapSchemas();
  }

  extractValues(model) {
    return model?.map(({ id }) => id).join(",") || "";
  }
}
</script>

<style lang="scss">
.post_project {
  .md-button {
    flex-basis: 115px;

    font-weight: 700;

    &.md-primary {
      color: #fff !important;
      background-color: #00acc1;
    }

    &.md-accent {
      background-color: #ffae00;
    }
  }

  .post_project-form .md-list {
    flex-direction: row;
    padding: 0 15px;
    align-items: center;
    border: 2px solid #ffae00;

    .field-item {
      flex: 1;

      .md-chips {
        flex-wrap: unset;
      }
    }

    .md-input.typeahead-list__input,
    .md-input.typeahead-list__input:focus,
    .sub-radio-container .md-input,
    .sub-radio-container .md-input:focus {
      border-bottom: unset !important;
      box-shadow: none !important;
    }

    .md-theme.md-input-container.md-input-focused {
      label {
        color: #ffae00;
      }
    }

    .md-list-text-container:has(.md-input-container.md-input-focused),
    .md-list-text-container:has(.md-input-container.md-has-value) {
      .field-caption {
        display: none;
      }
    }

    .autocomplete-container .md-input-container.tdl-typeahead {
      margin: 0;

      &::after {
        display: none;
      }
    }

    #post-languages .type-autocomplete {
      border-right: 1px solid #e0e0e0;
    }
  }
}
</style>
