<template>
  <div class="search-results-container" v-if="!loading">
    <div class="results-list">
        <div v-for="provider in providerList" :key="provider.id" class="results-list-item">
            <voice-actor-card
                :provider="provider"
                extended="true"
                :force-link-default="true"
            ></voice-actor-card>
        </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Prop, Vue, Watch } from 'vue-property-decorator';
import VoiceActorCard from './VoiceActorCard.vue';
import { getUrlParams } from '../utils';
import api from '../api';
@Options({
  components: {
    VoiceActorCard
  }
})
export default class SearchResult extends Vue {
  providerList: Array<any> = [];
  loading = true;
  @Prop()
  searchUrl!: string;
  @Prop({ default: '0' })
  offset!: string;

  @Watch('searchUrl')
  async getProvidersList(): Promise<void> {
    const that = this;
    const url = new URL(this.searchUrl);
    const querystring = getUrlParams(url.search);
    const {data} = await api.get('providers/search/', {
      params: {
        only_sample: true,
        service: 'voice_over',
        ...querystring
      }
    });
    this.loading = false;
    if (data.providers && data.providers.length) {
        const offset = Number(this.offset);
        if (offset) {
            data.providers.splice(0, offset) 
        }
        this.providerList = data.providers;
    }
  }
  mounted(): void {
    if(this.searchUrl) {
      this.getProvidersList();
    } else {
      throw new Error('The search URL is required to render the v123SearchResults component');
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "../../node_modules/@Voice123/v1_material/core/stylesheets/core.scss";
  @import"../../sass/v123.scss";

  .results-list-item {
    padding-bottom: 1px;
  }
  .results-stats {
    padding: 0 8px;
    font-family: Muli, Helvetica, Arial, sans-serif;
    font-weight: 200;
    font-style: normal;
    font-size: 26px;
    color: black;
  }
</style>