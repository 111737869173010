<template>
    <div class="steps-container">
        <div class="step-one">
            <div class="instructions-container">
                <h3>1. Insert your voice over script length</h3>
                <div class="radio-wrapper">
                    <div>
                        <md-radio md-value="1" v-model="wordCountSelected" class="md-primary">By word count</md-radio>
                        <md-layout md-gutter md-row>
                            <md-input-container class="word-container">
                                <label for="search-keyword" class="placeholder-label"></label>
                                <md-input :disabled="!wordCountSelected" min="0" :required="wordCountSelected === 1"
                                    type="number" v-model="words" data-hj-allow class="browser-default"></md-input>
                            </md-input-container>
                            <div class="time-unit-container">
                                <h4 class="words-text">words</h4>
                            </div>

                        </md-layout>
                    </div>
                    <div>
                        <md-radio md-value="1" v-model="scriptBoxSelected" class="md-primary">Calculate words for
                            me</md-radio>
                        <md-layout md-gutter md-row>
                            <md-input-container class="script-container">
                                <label for="script-input" class="placeholder-label">Voice-over script</label>
                                <md-textarea id="script-input" :required="scriptBoxSelected === 1" v-model="scriptText"
                                    data-hj-allow>
                                </md-textarea>
                            </md-input-container>
                            <div class="script-counter-container">
                                <p class="script-counter-text paste-script">Paste the full voice over script of the
                                    recording </p>
                                <p class="script-counter-text word-counter">{{ scriptLength }} words</p>
                            </div>
                        </md-layout>
                    </div>
                </div>
            </div>
        </div>
        <div class="step-two">
            <div class="instructions-container">
                <h3>2. Get your words-to-time estimation</h3>
                <div class="radio-wrapper">
                    <div>
                        <h3 class="reading-time">{{ readingTime }}</h3>
                        <h4 class="reading-speed">Voice actor's reading speed: {{ wordsPerMinute }} words per minute
                        </h4>
                        <h4 class="recommendation-text">Recommended for: {{ recommendation }}</h4>
                    </div>
                    <div>
                        <md-layout md-gutter md-row>
                            <p class="speed-text">Adjust the voice over speed</p>
                            <div class="slider-container">
                                <vdl-slider class="slider" :value="initialSliderValue" keyboardStep="15"
                                    @valueChange="calculateWpm"></vdl-slider>
                                <div class="mobile-slider-text">
                                    <h5>Slower</h5>
                                    <h5 class="left-text">Faster</h5>
                                </div>
                            </div>
                        </md-layout>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { Vue, Watch } from 'vue-property-decorator';
import VdlSlider from './vdlSlider/vdlSlider.vue';

export default class VoiceOverTimer extends Vue {
    wordCountSelected: any = 1;
    scriptBoxSelected: any = false;
    scriptText: string = '';
    words: number = 0;
    readingTime: any = '0 seconds';
    wordsPerMinute: number = 140;

    recommendations = {
        120: 'Slow Audiobook Narrration',
        130: 'Audiobook Narration',
        140: 'Conversational Script',
        150: 'Explainer Script',
        160: 'Fast Explainer Script',
        180: 'Commercial Script',
        190: 'Fast Commercial Script',
    };

    @Watch('scriptBoxSelected')
    onScriptBoxChanged() {
        this.wordCountSelected = !this.scriptBoxSelected ? 1 : false;
        this.readingTime = this.getReadingTime();
    }

    @Watch('wordCountSelected')
    onWordCounterChanged() {
        this.scriptBoxSelected = !this.wordCountSelected ? 1 : false;
        this.readingTime = this.getReadingTime();
    }

    @Watch('words')
    onWordsChanged() {
        this.readingTime = this.getReadingTime();
    }

    @Watch('scriptText')
    onScriptTextChanged() {
        this.readingTime = this.getReadingTime();
    }

    @Watch('wordsPerMinute')
    onWpmChanged() {
        this.readingTime = this.getReadingTime();
    }

    calculateWpm(sliderValue) {
        const maxWpm = 190;
        const minWpm = 120;
        this.wordsPerMinute = minWpm + Math.round(sliderValue * (maxWpm - minWpm) / 100);
    }

    getReadingTime() {
        const complement = 'seconds';
        let time = 0;
        if (this.scriptBoxSelected) {
            time = this.scriptText ? Math.round(this.scriptText.split(' ').filter(x => x).length * (60 / this.wordsPerMinute)) : 0;
        } else if (this.wordCountSelected) {
            time = this.words ? Math.round(this.words * (60 / this.wordsPerMinute)) : 0;
        }
        if (time > 90) {
            const minutes = Math.floor(time / 60);
            const seconds = time - minutes * 60;
            return `${minutes} ${minutes === 1 ? 'minute' : 'minutes'} ${seconds} ${seconds === 1 ? 'second' : 'seconds'}`;
        } else {
            return `${time} ${time === 1 ? 'second' : complement}`;
        }
    }

    get initialSliderValue() {
        const maxWpm = 190;
        const minWpm = 120;
        const value = Math.round(Math.abs(this.wordsPerMinute - minWpm) * 100 / Math.abs(maxWpm - minWpm));
        return value;
    }

    get recommendation() {
        const temp = Object.keys(this.recommendations).reduce((acc, current: any) => {
            const difference = Math.abs(this.wordsPerMinute - current);
            acc[difference] = current;
            return acc;
        }, {});
        const differencesArr = [...Object.keys(temp)].sort((a: any, b: any) => (a - b));
        return this.recommendations[temp[differencesArr[0]]];
    }

    get scriptLength() {
        return this.scriptText ? this.scriptText.split(' ').filter(x => x).length : 0;
    }

    mounted() {
        this.readingTime = this.getReadingTime();
    }
}
</script>

<style lang="scss" scoped>
@import "../../node_modules/@Voice123/v1_material/core/stylesheets/core.scss";
@import"../../sass/v123.scss";

.steps-container {
    display: grid;
    grid-template-columns: 49% 49%;
    align-items: center;
    justify-content: space-between;
    position: relative;
    margin: 50px auto 0;

    @media screen and (min-width: 721px) {
        min-height: 336px;
    }



    @media screen and (max-width: 720px) {
        grid-template-columns: 100%;
        padding: unset;
    }

    .step-one,
    .step-two {
        border: 1px solid $brand-blue;
        border-radius: 10px;
        display: flex;
        align-items: center;
        height: 100%;
        padding: 2rem;
    }

    .step-one {
        grid-column: 1;
        height: 100%;
    }

    .step-two {
        grid-column: 2;

        @media screen and (max-width: 720px) {
            grid-column: 1;
            margin-top: 60px;
        }

        .reading-time {
            margin-top: 30px;
            font-size: 20px;
            font-weight: 600;
            line-height: 28px;
        }

        .reading-speed {
            margin-bottom: 0;
            margin-top: 30px;
            font-weight: 400;
            font-size: 16px;
            color: black;
            line-height: 20px;
        }

        .recommendation-text {
            margin-top: 0;
            font-weight: 400;
            font-size: 16px;
            color: black;
            line-height: 20px;
            margin-bottom: 20px;
        }
    }

    .instructions-container {
        width: 100%;
        text-align: left;
        height: 100%;

        @media screen and (min-width: 721px) and (max-width: 1120px) {
            width: 90%;
        }

        @media screen and (min-width: 1121px) and (max-width: 1380px) {
            width: 75%;
        }

        @media screen and (max-width: 720px) {
            width: 90%;
        }

        .words-text {
            margin-top: 36px;
            margin-bottom: 0px;
            font-size: 14px;
            font-weight: 400;
        }

        .word-container {
            width: 30%;
            margin-right: 20px;

            @media screen and (min-width: 721px) {
                max-width: 50px;
            }
        }

        .time-unit-container {
            width: 60%;

            @media screen and (min-width: 721px) {
                max-width: 100px;
            }
        }

        .script-container {
            max-width: 100%;
            margin-bottom: 0;
        }

        .script-counter-container {
            margin-top: 5px;
            display: inline-flex;
            width: 100%;

            @media screen and (max-width: 720px) {
                display: block;
            }

            .paste-script {
                text-align: left;
                width: 70%;

                @media screen and (max-width: 720px) {
                    width: 100%;
                    margin-bottom: 0;
                }
            }

            .word-counter {
                text-align: right;
                width: 30%;

                @media screen and (max-width: 720px) {
                    width: 100%;
                    text-align: left;
                    margin-top: 0;
                }
            }
        }

        .script-counter-text {
            font-size: 12px;
            color: rgba(0, 0, 0, .38);
        }

    }

    .radio-wrapper {
        padding: 0 10%;
        margin-top: 10px;
    }

    .slider-container {
        display: block;
        width: 100%;

        @media screen and (max-width: 720px) {
            display: block;
        }

        .mobile-slider-text {
            display: inline-flex;
            width: 70%;

            @media screen and (max-width: 720px) {
                width: 100%
            }

            .left-text {
                text-align: right;
            }

            h5 {
                width: 50%;
                margin: 0;
                font-size: 14px;
                color: rgba(0, 0, 0, 0.38);
            }
        }
    }

    .speed-text {
        margin-bottom: -3px;
        font-size: 12px;
        color: rgba(0, 0, 0, 0.38);
    }

    .slider {
        width: 70%;
        position: relative;
        top: 8px;
        margin: 0;

        @media screen and (max-width: 720px) {
            width: 100%
        }
    }
}
</style>