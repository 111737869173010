<template>
  <div className="pros-display">
    <div>
      <span
        v-for="(filter, index) in [
          'Featured',
          'English - British',
          'Female',
          'Spanish - Latin American Neutral',
          'Male',
          'Young adult',
          'Senior adult',
        ]"
        :className="`filter-item${activeFilter === filter ? ' active' : ''}`"
        @click="handleClick(filter)"
        :key="filter"
        >{{ filter }}</span
      >
    </div>
    <ProsCarousel
      v-if="proActors?.length"
      :proActors="proActors"
      :isMobile="isMobile"
    />
  </div>
</template>

<script lang="ts">
import { Vue, Watch, Options } from "vue-property-decorator";
import ProsCarousel from "./ProsCarousel.vue";
import { default as proActorsList } from "./vaActors.json";
import { window } from "@Voice123/v1_material/core/browser";
import api from "../../api";

@Options({ components: { ProsCarousel } })
export default class ProActors extends Vue {
  // proActors = proActorsList.filter(actor => actor.featured);
  proActors = [];
  activeFilter = "Featured";
  isMobile = window.matchMedia("(max-width: 960px)").matches;

  updateProActors(newFilter) {
    if (newFilter === "Featured") {
      this.proActors = proActorsList.filter((actor) => actor.featured);
    } else {
      this.proActors = proActorsList.filter(
        (actor) =>
          actor.language === newFilter ||
          actor.gender === newFilter ||
          actor.age === newFilter
      );
    }
  }

  @Watch("activeFilter")
  onActiveFilterChange(newFilter) {
    this.updateProActors(newFilter);
  }

  @Watch("isMobile")
  onIsMobileChange() {
    this.updateProActors(this.activeFilter);
  }

  handleClick(filter: string) {
    this.activeFilter = filter;
  }

  async mounted() {
    const headers = {
      "studio-service": true,
    };
    const { data: attributes } = await api.get("attributes", { headers });
    const params = {
      size: 20,
      languages: [1018, 1017, 1088].join(',')
    }
    const { data: pros } = await api.get("providers/search", {
      headers,
      params
    });

    const attributesMap = attributes?.reduce(
      (obj, { name, values }) => ({
        ...obj,
        [name]: values.reduce((o, { id, name }) => ({ ...o, [id]: name }), {}),
      }),
      {}
    );

    this.proActors = pros?.providers.map((p) => {
      const [lang] = p.languages;
      const [voiceAge] = p.voice_age_genders;

      return {
        name: p.user.name,
        language: attributesMap.languages[lang],
        gender: attributesMap.voice_age_genders[voiceAge],
        // "age": "Young adult",
        image: p.user.picture_large,
        rate: "TBD",
        turnaround: 1,
        bookingLink: "https://voice123.com/book/marianascaffo",
        sampleUrl:
          "https://voice123.com/samples/SU/Voice123 - Commercial Demo - Elliott Lowe.mp3",
        featured: true,
      };
    });

    this.checkIfMobile();
    window.addEventListener("resize", this.checkIfMobile);
  }

  beforeDestroy() {
    window.removeEventListener("resize", this.checkIfMobile);
  }

  checkIfMobile() {
    this.isMobile = window.matchMedia("(max-width: 960px)").matches;
  }
}
</script>
