<template>
  <div className="pros-display">
    <div>
      <span
        v-for="(filter, index) in [
          'Featured',
          'English - British',
          'Spanish - Latin American Neutral',
          'Female adult',
          'Female senior',
          'Male adult',
          'Male senior',
        ]"
        :className="`filter-item${activeFilter === filter ? ' active' : ''}`"
        @click="handleClick(filter)"
        :key="filter"
        >{{ filter }}</span
      >
    </div>
    <ProsCarousel
      v-if="proActors?.length"
      :proActors="proActors"
      :isMobile="isMobile"
    />
  </div>
</template>

<script lang="ts">
import { Vue, Watch, Options } from "vue-property-decorator";
import ProsCarousel from "./ProsCarousel.vue";
import { default as vaActors } from "./vaActors.json";
import { window } from "@Voice123/v1_material/core/browser";
import api from "../../api";

@Options({ components: { ProsCarousel } })
export default class ProActors extends Vue {
  // proActors = proActorsList.filter(actor => actor.featured);
  proActors = [];
  proActorsList = vaActors;
  activeFilter = "Featured";
  isMobile = window.matchMedia("(max-width: 960px)").matches;

  updateProActors(newFilter) {
    if (newFilter === "Featured") {
      this.proActors = this.proActorsList;
    } else {
      this.proActors = this.proActorsList.filter(
        (actor) =>
          actor.language === newFilter ||
          actor.gender === newFilter
      );
    }
  }

  @Watch("activeFilter")
  onActiveFilterChange(newFilter) {
    this.updateProActors(newFilter);
  }

  @Watch("isMobile")
  onIsMobileChange() {
    this.updateProActors(this.activeFilter);
  }

  handleClick(filter: string) {
    this.activeFilter = filter;
  }

  async mounted() {
    const headers = {
      "studio-service": true,
    };
    const { data: attributes } = await api.get("attributes");
    const params = {
      size: 32,
      languages: [1018, 1017, 1088, 1054].join(',')
    }
    const { data: pros } = await api.get("providers/search", {
      headers,
      params
    });

    const attributesMap = attributes?.reduce(
      (obj, { name, values }) => ({
        ...obj,
        [name]: values.reduce((o, { id, name }) => ({ ...o, [id]: name }), {}),
      }),
      {}
    );

    this.proActorsList = pros?.providers.map((p) => {
      const [lang] = p.languages;
      const [voiceAge] = p.voice_age_genders;

      return {
        name: this.formatName(p.user.name),
        language: attributesMap.languages[lang],
        gender: attributesMap.voice_age_genders[voiceAge],
        image: p.user.picture_large || p.user.picture_small || p.user.picture_medium || 'https://voice123.com/static/profile_pic.png',
        turnaround: 1,
        sampleUrl: p.relevant_sample.file,
        profileUrl: `https://voice123.com/voice-actor/${p.user.username}` || '',
      };
    });

    this.updateProActors(this.activeFilter);

    this.checkIfMobile();
    window.addEventListener("resize", this.checkIfMobile);
  }

  beforeDestroy() {
    window.removeEventListener("resize", this.checkIfMobile);
  }

  checkIfMobile() {
    this.isMobile = window.matchMedia("(max-width: 960px)").matches;
  }

  formatName(name: string) {
    return name.split(" ").map((n, index) => index === 0 ? n : n[0]).join(" ");
  }
}
</script>
