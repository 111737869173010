<template>
    <div>
        <div class="rate-container" v-show="attributes.length > 0">
            <div class="va-requirements">
                <md-card>
                    <md-card-header>
                        <div class="md-title">
                            <h3 class="box-title">1. Your voice actor requirements</h3>
                        </div>
                    </md-card-header>
                    <md-card-content>
                        <div class="filter-search">
                            <div md-flex="100">
                                <div md-flex="100">
                                    <form style="width:100%;" class="formkit-form" id="rateFilterModel"
                                        name="rateFilterModel">
                                        <div class="form-content">
                                            <md-list class="fields md-double-line">
                                                <li class="field-item autocomplete">
                                                    <ul class="unestiled-list">
                                                        <div id="languages">
                                                            <div class="field-item-list">
                                                                <vdl-autocomplete-form-field :field="filtersSchema[0]"
                                                                    v-model="languagesModel" :svgIcon="mdiTranslate"
                                                                    :name="filtersSchema[0].name"
                                                                    @update:modelValue="(model) => updateModel(filtersSchema[0].attribute, model)">
                                                                    <md-list class="fields md-double-line">
                                                                        <li class="nested">
                                                                            <ul class="unestiled-list">
                                                                                <div id="languages_operator">
                                                                                    <div class="field-item-list">
                                                                                        <vdl-form-field
                                                                                            class="type-switch">
                                                                                            <div class="switch-heading">
                                                                                                <div
                                                                                                    class="switch-value">
                                                                                                    <span>
                                                                                                        <label
                                                                                                            for="languages_operator"
                                                                                                            @click.prevent>The
                                                                                                            voice actor
                                                                                                            must
                                                                                                            perform in
                                                                                                            all
                                                                                                            the
                                                                                                            languages
                                                                                                            selected
                                                                                                            (fewer
                                                                                                            results)</label>
                                                                                                        <md-switch
                                                                                                            label-turn-on="turn on"
                                                                                                            label-turn-off="turn off"
                                                                                                            md-theme="v123"
                                                                                                            v-model="languagesOperatorModel"
                                                                                                            id="languages_operator"
                                                                                                            name="languages_operator"
                                                                                                            class="md-primary"
                                                                                                            @update:modelValue="(model) => updateModel('languages_operator', model)"></md-switch>
                                                                                                    </span>
                                                                                                </div>
                                                                                            </div>
                                                                                        </vdl-form-field>
                                                                                    </div>
                                                                                </div>
                                                                            </ul>
                                                                        </li>
                                                                    </md-list>
                                                                </vdl-autocomplete-form-field>
                                                            </div>
                                                        </div>
                                                    </ul>
                                                </li>
                                                <li class="field-item checkbox">
                                                    <ul class="unestiled-list">
                                                        <div data-v-da8ba575="" data-v-e0e1f106="" tabindex="-1"
                                                            button-aria-label="Voice gender and age" order="2"
                                                            id="voice_age_genders" operators="true" plural="gender/ages"
                                                            view="chips" emptymsg="Any" excludedvalues="">
                                                            <vdl-form-field
                                                                class="field-item-list type-checkbox gender-age-expanded md-active"
                                                                tabindex="-1" :button-aria-label="filtersSchema[1].name"
                                                                :always-expanded="true">
                                                                <md-icon :mdSvg="mdiHumanMaleFemale" />
                                                                <div class="md-list-text-container">
                                                                    <span class="field-name">{{ filtersSchema[1].name
                                                                        }}</span>
                                                                </div>
                                                                <md-list-expand>
                                                                    <md-list>
                                                                        <div v-for="(item, index) in filtersSchema[1].values"
                                                                            :key="item.id">
                                                                            <vdl-list-checkbox
                                                                                :class="{ 'last-item': index === filtersSchema[1].values.length - 1, 'gender-age-expanded': true }"
                                                                                type="checkbox" :vdl-value="item"
                                                                                :id="'voice_age_genders-' + item.id"
                                                                                :name="filtersSchema[1].name"
                                                                                v-model="ageGenderModel"
                                                                                @update:modelValue="(model) => updateModel(filtersSchema[1].attribute, model)">
                                                                                <div class="vdl-list-checkbox-text">
                                                                                    <label
                                                                                        :for="'voice_age_genders-' + item.id"
                                                                                        class="primary-text">{{
            item.name
        }}</label>
                                                                                </div>
                                                                            </vdl-list-checkbox>
                                                                        </div>
                                                                    </md-list>
                                                                </md-list-expand>
                                                            </vdl-form-field>
                                                        </div>

                                                    </ul>
                                                </li>
                                            </md-list>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </md-card-content>
                </md-card>
            </div>
            <div class="script-estimation">
                <div class="details-box">
                    <md-card>
                        <md-card-header>
                            <div class="md-title">
                                <h3 class="box-title">2. Your Script</h3>
                            </div>
                        </md-card-header>
                        <md-card-content>
                            <div class="instructions-container">
                                <div class="radio-wrapper">
                                    <div>
                                        <md-radio md-value="1" v-model="wordCountSelected"
                                            class="md-primary sub-header">Calculate rate by word count</md-radio>
                                        <div md-gutter md-row class="sub-radio-container">
                                            <md-input-container>
                                                <label for="search-keyword" class="placeholder-label"></label>
                                                <md-input :disabled="!wordCountSelected" min="0"
                                                    :required="wordCountSelected === 1" type="number" v-model="words"
                                                    ref="searchInput" placeholder="" data-hj-allow></md-input>
                                            </md-input-container>
                                            <div class="time-unit-container">
                                                <h4 class="words-text">words</h4>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <md-radio md-value="1" v-model="scriptBoxSelected"
                                            class="md-primary sub-header">Calculate rate for me</md-radio>
                                        <div md-gutter md-row class="sub-radio-container">
                                            <md-input-container class="script-container">
                                                <label for="script-input" class="placeholder-label">Script</label>
                                                <md-textarea id="script-input" :required="scriptBoxSelected === 1"
                                                    :disabled="scriptBoxSelected !== 1" v-model="scriptText"
                                                    data-hj-allow>
                                                </md-textarea>
                                            </md-input-container>
                                            <div class="script-counter-container">
                                                <p class="script-counter-text paste-script">Paste the full script of the
                                                    recording </p>
                                                <p class="script-counter-text word-counter">{{ scriptLength }} words</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </md-card-content>
                    </md-card>
                </div>
                <div class="details-box">
                    <md-card>
                        <md-card-header>
                            <div class="md-title">
                                <h3 class="box-title">3. Get your estimate</h3>
                                <div class="quote-info-container">
                                    <md-icon :mdSvg="mdiInformationOutline" />
                                    <h4 class="caption-text estimation-caption">Note that none of these projects include
                                        usage costs. Such costs
                                        will be additional. The estimates are provided in USD.</h4>
                                </div>
                            </div>
                        </md-card-header>
                        <md-card-content>
                            <div class="instructions-container">
                                <div class="radio-wrapper" style="padding-left: 4%;">
                                    <div>
                                        <div style="display: inline-flex;">
                                            <md-icon class="estimation-icon" :mdSvg="mdiCash" />
                                            <p>Average Project Cost</p>
                                        </div>
                                        <div style="display: flex; padding-left: 45px; position: relative;">
                                            <h4 class="words-text currency-text">USD $</h4>
                                            <div class="time-unit-container budget-quote-container">
                                                <h4 class="words-text" v-show="!calculatingRate">{{ rate }}</h4>
                                                <div md-flex="80">
                                                    <md-progress md-indeterminate
                                                        v-show="calculatingRate"></md-progress>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </md-card-content>
                    </md-card>
                </div>
            </div>
        </div>
        <div class="recommended" v-show="rate">
            <h2>Recommended voice actors based on your project requirements</h2>
            <search-result :searchUrl="searchUrl"></search-result>
            <md-button :href="searchUrl" target="_blank" class="md-primary md-raised btn">VIEW MORE</md-button>
        </div>
    </div>
</template>

<script lang="ts">

import { Vue, Watch, Options } from 'vue-property-decorator';
import api from '../api';
import { mdiTranslate, mdiHumanMaleFemale, mdiInformationOutline, mdiCash } from '@mdi/js';
import mapAttributesToSchema from '../helpers';
import SearchResult from './SearchResults.vue';

@Options({
  components: {
    SearchResult
  }
})

export default class RateCalculator extends Vue {
    mdiInformationOutline = mdiInformationOutline;
    mdiCash = mdiCash;
    mdiTranslate = mdiTranslate;
    mdiHumanMaleFemale = mdiHumanMaleFemale;
    wordCountSelected: any = 1;
    scriptBoxSelected: any = false;
    calculatingRate: boolean = false;
    scriptText: string = '';
    rate: any = 0;
    words: any = '';
    attributes: Array<any> = [];
    languagesModel: any = [];
    languagesOperatorModel: any = false;
    ageGenderModel: any = [];
    filtersSchema = [
        {
            order: 1,
            attribute: 'languages',
            svgIcon: mdiTranslate,
            name: 'Language',
            operators: true,
            type: 'autocomplete',
            values: [],
            noInsetNested: true,
            threshold: -1,
            nested: [
                {
                    attribute: 'languages_operator',
                    type: 'switch',
                    name: 'The voice actor must perform in all the languages selected (fewer results)'
                }
            ]
        },
        {
            order: 2,
            attribute: 'voice_age_genders',
            id: 'voice_age_genders',
            svgIcon: mdiHumanMaleFemale,
            name: 'Voice gender and age',
            operators: true,
            plural: 'gender/ages',
            type: 'checkbox',
            always_expanded: true,
            view: 'chips',
            operators_attribute: 'voice_age_genders_operators',
            values: [],
            classes: {
                'gender-age-expanded': true
            },
            emptyMsg: 'Any',
            nested: [
                {
                    attribute: 'voice_age_genders_operator',
                    id: 'voice_age_gender',
                    type: 'switch',
                    name: 'The voice actor must perform in all the gender & ages selected (fewer results)'
                }
            ],
        },
    ];
    filtersModel: any = {};


    @Watch('scriptBoxSelected')
    onScriptBoxChanged() {
        this.wordCountSelected = !this.scriptBoxSelected ? 1 : false;
    }

    @Watch('wordCountSelected')
    onWordCounterChanged() {
        this.scriptBoxSelected = !this.wordCountSelected ? 1 : false;
    }

    @Watch('words')
    async onWordsChanged() {
        this.filtersModel.script_length = this.words;
        this.filtersModel = { ...this.filtersModel };
    }


    @Watch('scriptText')
    async onScriptTextChanged() {
        this.filtersModel.script_length = this.scriptLength;
        this.filtersModel = { ...this.filtersModel };
    }

    @Watch('filtersModel')
    async onFiltersModelChanged() {
        if (this.filtersModel.script_length && this.filtersModel.modelChanged) {
            this.calculatingRate = true;
            await this.getAverageRate();
            this.calculatingRate = false;
        }
    }

    get scriptLength() {
        return this.scriptText ? this.scriptText.split(' ').filter(x => x).length : 0;
    }

    get searchUrl() {
        return `https://voice123.com/search?${this.filtersModel.languages?.length && 'languages=' + this.filtersModel.languages.join(',')}&${this.filtersModel.voice_age_genders?.length && 'voice_age_genders=' + this.filtersModel.voice_age_genders.join(',')}&page=1`;
    }

    fetchListForField(query, initialValue = false) {
        return new Promise((resolve) => {
            if (query) {
                const matching = this.filtersSchema[0].values.filter((f) => {
                    const name = f.name.toLowerCase();
                    const q = query.toLowerCase();
                    return initialValue ? name.indexOf(q) == 0 : name.indexOf(q) >= 0;
                });
                resolve(matching);
            } else {
                resolve(this.filtersSchema[0].values);
            }
        });
    }

    async getAverageRate() {
        const response = await api.get(`providers/averageRate`, { params: this.filtersModel });
        this.rate = Math.round(response.data.medianRate);
    }

    async mapSchemas() {
        this.filtersSchema = mapAttributesToSchema(this.attributes, this.filtersSchema);
    }

    async updateModel(key, value) {
        this.filtersModel.modelChanged = true;
        this.filtersModel[key] = Array.isArray(value) ? value.map(v => v.id) : value;
        this.filtersModel = { ...this.filtersModel };
    }

    async mounted() {
        const { data } = await api.get('attributes/');
        this.attributes = data;
        this.mapSchemas();
    }
}
</script>

<style lang="scss">
@import "../../node_modules/@Voice123/v1_material/core/stylesheets/core.scss";
@import"../../sass/v123.scss";

.rate-container {

    .script-estimation {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .details-box {
            margin-top: 35px;
        }

        .quote-info-container {
            display: inline-flex;
            justify-content: left;
            align-items: center;
            width: 100%;
            padding-left: 4%;
            margin: 20px 0;

            .estimation-caption {
                margin: 0 0 0 20px;
                text-align: left;
                font-size: 14px;
                line-height: 20px;
                font-weight: 400;
                color: #0000008a;

                @media screen and (min-width: 721px) {
                    width: 400px;
                }

                @media screen and (max-width: 720px) {
                    text-align: left;
                }
            }

            .md-icon {
                margin: 0;
            }
        }
    }

    .md-card {
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    }

    .box-title {
        text-align: left;
        font-size: 26px;
        font-weight: 400;
        margin-left: 15px;
    }

    .md-input.typeahead-list__input,
    .md-input.typeahead-list__input:focus,
    .sub-radio-container .md-input,
    .sub-radio-container .md-input:focus {
        border-bottom: unset !important;
        box-shadow: none !important;
    }

    .switch-heading {
        width: 100%;

        span {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        label {
            font-size: 12px;
        }
    }

    .md-list-expand-indicator {
        display: none !important;
    }

    .md-list {
        padding: 0;
    }

    .md-list.md-double-line .md-list-item .md-list-item-container {
        min-height: 56px;
    }

    .gender-age-expanded .md-inset {
        padding: 0 0 0 34px !important;
    }

    .operator-wrapper {
        min-height: 32px;
        margin-bottom: 8px;
        margin-top: -24px;
    }

    .type-switch .md-list-item-container {
        padding: 0;
    }

    .md-switch {
        margin: 0;
        margin-left: 7px;
    }

    .instructions-container {
        text-align: left;
        height: 100%;
        padding-bottom: 72px;

        @media screen and (min-width: 721px) and (max-width: 1120px) {
            width: 90%;
        }

        @media screen and (min-width: 1121px) and (max-width: 1380px) {
            width: 75%;
        }

        @media screen and (max-width: 720px) {
            width: 90%;
        }

        .words-text {
            margin-top: 36px;
            margin-bottom: 0px;
            font-size: 14px;
            font-weight: 400;
        }

        .time-unit-container {
            width: 60%;

            @media screen and (min-width: 721px) {
                max-width: 100px;
            }
        }

        .script-container {
            max-width: 100%;
            margin-bottom: 0;
        }

        .script-counter-container {
            margin-top: 5px;
            display: inline-flex;
            width: 100%;

            @media screen and (max-width: 720px) {
                display: block;
            }

            .paste-script {
                text-align: left;
                width: 70%;

                @media screen and (max-width: 720px) {
                    width: 100%;
                    margin-bottom: 0;
                }
            }

            .word-counter {
                text-align: right;
                width: 30%;

                @media screen and (max-width: 720px) {
                    width: 100%;
                    text-align: left;
                    margin-top: 0;
                }
            }
        }

        .script-counter-text {
            font-size: 12px;
            color: rgba(0, 0, 0, .38);
        }

    }

    .radio-wrapper {
        padding-left: 3rem;
        text-align: left;

        .words-text {
            position: relative;
            top: 1px;
            left: 20px;
            font-size: 18px;
            font-weight: 400;
        }

        .sub-radio-container {
            padding-left: 60px;

            &:has(.time-unit-container) {
                display: flex;
            }

            .md-input-container {
                width: 50px;
            }

            .script-container {
                width: 90%;
                margin-bottom: 8px;
            }

            .script-counter-container {
                p {
                    font-size: 12px;
                    color: rgba(0, 0, 0, .54);
                }

                .script-counter-text {
                    margin-top: 0px;
                }

                .paste-script {
                    width: 60%;
                }

                .word-counter {
                    width: 30%;
                    text-align: right;
                }
            }
        }

        p {
            margin-bottom: unset;
            margin-left: 1rem;
        }
    }

    .md-list-item-container .md-button {
        cursor: auto !important;
    }

    .gender-age-expanded {
        .md-list-expand {
            overflow-y: scroll !important;
        }

        .md-inset {
            padding: 0 0 0 34px !important;
        }
    }

    .vdl-list-checkbox .md-list-item-container {
        padding-left: 36px !important;
    }

    .md-button:focus {
        background-color: unset;
    }

    .primary-text {
        color: $text-contrast-87;
    }


    @media screen and (min-width: $content-breakpoint) {
        display: flex;
        justify-content: space-between;

        .va-requirements {
            width: 50%;
        }

        .script-estimation {
            width: 47%;

            .details-box {
                margin-top: 0;
            }
        }

    }

    .md-list-item-expand>.md-list-expand {
        padding: 20px;
        height: auto;
        width: 100%;
        display: block;
        transform: scaleY(1);
        transform-origin: top;
        transition: transform .4s ease;
        box-sizing: border-box;
    }
}

.recommended {
    margin-top: 7rem;
    .results-list {
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    .md-button {
        margin-top: 30px;
    }
}
</style>