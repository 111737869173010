import { createApp } from 'vue'
import components from './components/index'
import * as config from './config';
import { createHead } from '@vueuse/head';
import '../node_modules/@Voice123/v1_material/core/stylesheets/core.scss';
import '../sass/v123.scss';

const app = createApp({})
const head = createHead();

components.forEach(({component, name} ) => {
  app.component(component);
  document
    .querySelectorAll(`[v123-component=${name}]`)
    .forEach((element: any) => {
      const instance: any = createApp(component, element.dataset);
      instance.use(head)
      instance.use(config)
      instance.mount(element);
    });
});