<template>
  <div class="va-carousel-container providers-carrusel">
    <div class="carrousel" :class="{'hide-boards-navigation': providerList && providerList.length <= 1}">
      <md-boards v-if="providerList.length > 0" ref="boards" :md-infinite="false" :md-controls="false" :md-swipe-distance="1" :md-swipeable="true" :md-active="0">
          <md-board v-for="provider in providerList" :key="provider.id" :id="`board${provider.id}`">
            <md-layout class="providers">
              <div  class="carousel-item">
                <voice-actor-card
                  :provider="provider"
                  extended="false"
                  :force-link-default="true"
                ></voice-actor-card>
              </div>
            </md-layout>
          </md-board>
      </md-boards>
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Prop, Vue } from 'vue-property-decorator';
import { getUrlParams } from '../utils';
import VoiceActorCard from './VoiceActorCard.vue';
import api from '../api';
@Options({components: { VoiceActorCard }})
export default class VoiceActorCarrousel extends Vue {
  providerList: Array<any> = [];
  @Prop()
  searchUrl!: string;
  async getProvidersList(): Promise<void> {
    const that = this;
    const url = new URL(this.searchUrl);
    const querystring = getUrlParams(url.search);
    const {data} = await api.get('providers/search/', {
      params: {
        only_sample: true,
        service: 'voice_over',
        ...querystring,
        size: 4,
      }
    });
    if (data.providers && data.providers.length) {
      this.providerList = data.providers;
      this.loadCarrousels();
    }
  }
  loadCarrousels():void {
    let that = this;
    setTimeout(function() {
      (that.$refs.boards as any).setActiveBoardByIndex(0);
    }, 1);
  }
  mounted(): void {
    if(this.searchUrl) {
      this.getProvidersList();
    } else {
      throw new Error('The SearchUrl is required to render the v123VoiceActorCarrousel component');
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "../../node_modules/@Voice123/v1_material/core/stylesheets/core.scss";
  @import"../../sass/v123.scss";
  
  .carrousel {
    .md-boards {
      .md-boards-navigation-container {
        .md-boards-navigation {
          div {
            button {
              align-self: baseline;
            }

            .md-board-header {
              color: rgba(0,0,0,.54);
            }
          }
        }
      }
    }
  }
.providers-carrusel {
  .carrousel {
    display: flex;
    align-items: center;
    .carousel-item {
      width: 100%;
      .md-card.md-theme.provider-card {
        max-width: none !important;
        width: 95%;
        background: white;
      }
    }
    .side-control-wrapper {
        width: 52px;
    }

    .md-board {
        padding: 16px 0;

        .providers {
            justify-content: space-evenly;
            @media (min-width: 720px) {
                .provider-card.md-card {
                    max-width: 344px;
                }
            }
        }
    }
    .md-boards {
      .md-boards-navigation-container {
        display: none;
        box-shadow: none !important;
        -webkit-box-shadow: none !important;
        .md-boards-navigation {
          box-shadow: none !important;
          -webkit-box-shadow: none !important;
          button {
            align-self: baseline;
          }

          .md-board-header {
            color: rgba(0,0,0,.54);
          }
        }
      }
    }

    nav.md-boards-navigation{
      button{
        &.md-board-header{
          i{
            -webkit-text-stroke: 1px #1E88E5;
            -webkit-text-stroke-width: 1px;
            color: #eff6fd;
          }
        }
        &.md-active{
          i{
            color: #1E88E5;
          }
        }
      }
    }
    
}



  >.md-card-header {
    border-bottom: 1px solid $brand_blue;
  }

  >.md-card-content.onboarding p {
    margin-top: 20px;
  }

  >.md-card-content.normal {
    padding: 0;

    @media (min-width: 720px) {
      padding-top: 30px;
    }



    .md-card-actions {
      justify-content: center;

      .md-button.md-outlined {
        line-height: 36px;
      }
    }
  }
}


</style>