import VoiceActorCard from './VoiceActorCard.vue';
import VoiceActorCarrousel from './VoiceActorCarrousel.vue';
import SearchResults from './SearchResults.vue';
import VoiceOverTimer from './VoiceOverTimer.vue';
import LengthCalculator from './LengthCalculator.vue';
import RateCalculator from './RateCalculator.vue';
import PostProject from './PostProject.vue';
import ProActors from './ProActors/index.vue';

export default [
    { component: VoiceActorCard, name: 'VoiceActorCard' },
    { component: VoiceActorCarrousel, name: 'VoiceActorCarrousel' },
    { component: SearchResults, name: 'SearchResults' },
    { component: VoiceOverTimer, name: 'VoiceOverTimer' },
    { component: LengthCalculator, name: 'LengthCalculator' },
    { component: RateCalculator, name: 'RateCalculator' },
    { component: ProActors, name: 'ProActors' },
    { component: PostProject, name: 'PostProject' },
];