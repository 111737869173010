export const API_URL = 'https://voice123.com/api';
export const DEFAULT_THEME = 'v123';
export const THEME_DEFINITION = {
    v123: {
      primary: {
        color: 'blue',
        hue: 600
      },
      accent: {
        color: 'blue',
        hue: 600
      }
    }
}

export const THEMES = {
  v123: {
    primary: {
      color: 'blue',
      hue: 600
    },
    accent: {
      color: 'blue',
      hue: 600
    },
  },
  clean: {
    primary: {
      color: 'blue',
      hue: 600
    },
    accent: {
      color: 'blue',
      hue: 600
    }
  },
  light: {
    primary: {
      color: 'grey',
      hue: 200
    },
    accent: {
      color: 'blue',
      hue: 600
    }
  },
  white: {
    primary: 'white'
  },
  blue: {
    background: 'blue',
    primary: {
      color: 'blue',
      hue: 600
    }
  }
};
export const THEME = 'v123';
