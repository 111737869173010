<template>
  <div class="p123-carousel">
      <div class="side-control-wrapper vdl-no-mobile">
          <md-button class="md-icon-button md-primary" @click="previous" :disabled="boardIdx === 0"><md-icon :mdSvg="mdiChevronLeft"/></md-button>
      </div>
      <md-boards ref="boards" :md-infinite="false" :md-controls="false" :md-swipe-distance="2" :md-swipeable="true"
        @change="onChange"
      >
          <md-board v-for="(board, idx) in boards" :id="`board${idx}`" :key="`board${idx}`">
              <md-layout  v-if="(idx === boardIdx && isMobile) || !isMobile" class="actors">
                <div :class="{ 'cards-wrapper': true, 'pro-card-lonely': board.actors.length === 1 }">
                  <ProCard v-for="(actor, index) in board.actors" :key="index" v-bind="actor"/>
                </div>
              </md-layout>
          </md-board>
      </md-boards>
      <div class="vdl-no-mobile side-control-wrapper">
          <md-button class="md-icon-button md-primary" @click="next" :disabled="boardIdx === boards.length - 1">
            <md-icon class="md-icon" :mdSvg="mdiChevronRight"/>
          </md-button>
      </div>
  </div>
</template>

<script lang="ts">
import { Options, Prop, Vue, Watch } from 'vue-property-decorator';
import { mdiChevronLeft, mdiChevronRight } from '@mdi/js';
import ProCard from './ProCard.vue';

@Options({components: { ProCard }})
export default class ProsCarousel extends Vue {
  mdiChevronLeft  = mdiChevronLeft;
  mdiChevronRight = mdiChevronRight;
  boardIdx = 0;

  @Prop()
  proActors: Array<any>;

  @Prop()
  isMobile: boolean;

  @Watch('proActors')
  onProActorsChange() {
   this.boardIdx = 0;
   this.setActiveBoard(this.boardIdx);
  }

  get boardsLimit () {
    return this.isMobile ? 1 : 4;
  }

  get boards() {
        const boards = [];
        if (this.proActors && this.proActors.length ) {
            let i, j, chunk = this.boardsLimit ? this.boardsLimit : this.isMobile ? 1 : 4;
            for (i = 0, j = this.proActors.length; i < j; i += chunk) {
                const actors = this.proActors.slice(i, i + chunk);
                boards.push({ actors, activeBoard: this.boardIdx });
            }
        }
        return boards;
    }
  
  loadCarrousels():void {
    let that = this;
    setTimeout(function() {
      (that.$refs.boards as any).setActiveBoardByIndex(0);
    }, 1);
  }
  
  mounted() {
    if(this.proActors) {
      this.loadCarrousels();
    } else {
      throw new Error('The proActors is required to render the v123VoiceActorCarrousel component');
    }
  }

  previous() {
    if (this.boardIdx > 0) {
      this.boardIdx = this.boardIdx - 1;
      this.setActiveBoard(this.boardIdx);
    }
  }

  next() {
    if (this.boardIdx < this.proActors.length) {
      this.boardIdx = this.boardIdx + 1;
      this.setActiveBoard(this.boardIdx);
    }
  }

  onChange (index) {
    this.boardIdx = index;
  }

  setActiveBoard(index) {
      (this.$refs.boards as any).setActiveBoardByIndex(index);
  }
}
</script>
<style lang="scss">
  .p123-carousel {
    display: flex;
    align-items: center;
    &.hide-boards-navigation {
      .md-boards-navigation-container {
        display: none;
      }
    }

    .side-control-wrapper {
      display: flex;
      align-items: center;
      height: 100%;
      width: 52px;
      @media(max-width: 960px) {
        margin-top: 116px;
        
      }
      button {
          background-color: #fff;
          border-color: #00ACC1;
          .md-icon {
              color: #00ACC1;
              width: 3rem;
              height: 3rem;
          }
      }
    }

    .md-board {
      padding: 16px 0;

      .actors {
        justify-content: space-evenly;
        margin-left: 0;

        .full-width-card {
          flex: 100%;
        }
      }
    }

    nav.md-boards-navigation{
      button{
        &.md-board-header{
          .md-icon{
            -webkit-text-stroke: 1px #00ACC1;
            -webkit-text-stroke-width: 1px;
            color: #eff6fd;
          }
        }
        &.md-active{
          svg {
            path {
              stroke: #00ACC1;
              stroke-width: 2;
              fill: #00ACC1;
            }
          }
        }
        &:not(.md-active) {
          svg {
            path {
              stroke: #00ACC1;
              stroke-width: 2;
              fill: #ffff;
            }
          }
        }
      }
    }
    .pro-card-lonely {
      width: 100%;
    }
  }
</style>