import { Options, Prop, Vue } from 'vue-property-decorator';

@Options({})
export default class Slider extends Vue {

    @Prop({ default: 50 })
    value: number;

    @Prop({ default: 1 })
    keyboardStep: number;

    sliderValue = 0;

    sliderClick(e) {
        let sliderPosition = (this.$refs.slider as any).getBoundingClientRect();
        let newValue =
            ((e.clientX - sliderPosition.left) / sliderPosition.width) * 100;
        this.setValue(newValue, true);

        (this.$refs.slider as any).focus();
    }

    increment() {
        if (this.sliderValue === 100) {
            return;
        }
        this.setValue(this.sliderValue + this.keyboardStep, true);
    }

    decrement() {
        if (this.sliderValue === 0) {
            return;
        }
        this.setValue(this.sliderValue - this.keyboardStep, true);
    }

    setValue(newValue, updateThumb = false) {
        if (newValue === this.sliderValue) {
            return;
        }
        // Round the value
        let moderatedValue = Math.round(newValue);
        // Check for max
        if (moderatedValue >= 100) {
            moderatedValue = 100;
        }
        // Check for min
        if (moderatedValue <= 0) {
            moderatedValue = 0;
        }
        this.sliderValue = moderatedValue;
        if (updateThumb) {
            (this.$refs.thumb as any).style.left = this.sliderValue + '%';
        }
        this.$emit('valueChange', this.sliderValue);
    }


    mounted():  void {
        // Set initial value
        (this.$refs.thumb as any).style.left = this.value + '%';
        this.sliderValue = this.value;
    }
}
